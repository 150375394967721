import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'app-preview-media',
    templateUrl: './preview-media.component.html',
    styleUrls: ['./preview-media.component.scss'],
})
export class PreviewMediaComponent {
    @Input() listPreview: any[];
    @Input() initIndexPreview: number;
    @Output() closePreview = new EventEmitter();

    isPreviewMedia: boolean = false;
    currentIndexPreview: number = 0;

    ngOnInit() {
        this.currentIndexPreview = this.initIndexPreview ?? 0;
    }

    closePreviewModal(): void {
        this.closePreview.emit();
    }

    checkTypeMedia(type: string): 'IMAGE' | 'VIDEO' | 'FILE' {
        if (type.includes('image/')) return 'IMAGE';
        if (type.includes('video/')) return 'VIDEO';
        return 'FILE';
    }

    onScrollChangeImage(event: WheelEvent): void {
        const delta = event.deltaY > 0 ? 1 : -1;
        const newIndex = this.currentIndexPreview + delta;
        
        if (newIndex >= 0 && newIndex < this.listPreview.length) {
            this.currentIndexPreview = newIndex;
            this.scrollIntoPreviewMedia();
        }
    }

    scrollIntoPreviewMedia() {
        const target = document.getElementById('preview_image_' + this.currentIndexPreview) as HTMLElement;
        if (target) {
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent): void {
        this.closePreviewModal();
    }

    setPreviewURL(index: number) {
        this.currentIndexPreview = index;
        this.scrollIntoPreviewMedia();
    }

    previewFile(file: any): void {
        window.open(file.url, '_blank');
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/user-management/default-image.jpg';
    }
}