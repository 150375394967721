<div (click)="closePreviewModal()" class="fixed top-0 left-0 z-[99] w-full h-full bg-black bg-opacity-75 flex justify-center items-center">
    <div (click)="$event.stopPropagation()" class="bg-black !px-4 md:!px-8 !py-8 rounded-lg !m-2 relative h-fit">
        <button (click)="closePreviewModal()" class="absolute top-2 right-2 !w-6 !h-6 hover:bg-palette-gray-800 rounded-lg">
            <i class="sctr-icon-x-close text-base leading-4 text-white font-normal"></i>
        </button>
        <ng-container [ngSwitch]="checkTypeMedia(listPreview[currentIndexPreview].type)">
            <ng-container *ngSwitchCase="'IMAGE'">
                <img (wheel)="onScrollChangeImage($event)" [src]="listPreview[currentIndexPreview].url"
                    alt="Current Image" (error)="replaceWithDefaultImage($event)"
                    class="aspect-video rounded w-[900px] max-w-[calc(100vw-100px)] object-center object-contain">
            </ng-container>
            <ng-container *ngSwitchCase="'VIDEO'">
                <video (wheel)="onScrollChangeImage($event)" controls [src]="listPreview[currentIndexPreview].url" autoplay
                    class="aspect-video rounded w-[900px] max-w-[calc(100vw-100px)] object-center">
                </video>
            </ng-container>
            <ng-container *ngSwitchCase="'FILE'">
                <div (click)="previewFile(listPreview[currentIndexPreview])" (wheel)="onScrollChangeImage($event)" 
                    class="relative cursor-pointer rounded">
                    <img [src]="listPreview[currentIndexPreview].thumb" alt="DefaultImage" (error)="replaceWithDefaultImage($event)"
                        class="aspect-video rounded w-[900px] max-w-[calc(100vw-100px)] object-center object-contain cursor-pointer">
                    <div class="absolute top-0 bottom-0 right-0 left-0 z-[10] flex justify-center items-center bg-black bg-opacity-20">
                        <i class="sctr-icon-paperclip text-xl font-semibold text-palette-gray-500"></i>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <img (wheel)="onScrollChangeImage($event)" [src]="listPreview[currentIndexPreview].url"
                    alt="DefaultImage" (error)="replaceWithDefaultImage($event)"
                    class="aspect-video rounded w-[900px] max-w-[calc(100vw-100px)] object-center object-contain">
            </ng-container>
        </ng-container>
        <div class="flex items-center gap-2 overflow-hidden !mt-2 max-w-[900px] hide-scroll !py-2">
            <ng-container *ngFor="let media of listPreview; let idxMedia = index">
                <ng-container [ngSwitch]="checkTypeMedia(media.type)">
                    <ng-container *ngSwitchCase="'IMAGE'">
                        <img (click)="setPreviewURL(idxMedia)" src="{{media.url}}" alt="Img {{idxMedia}}" 
                            [id]="'preview_image_' + idxMedia" class="border aspect-square w-[76px] min-w-[76px] md:w-[100px] md:min-w-[100px] rounded 
                            cursor-pointer hover:scale-[1.02] transition-all {{idxMedia === currentIndexPreview ? 'border-white' : 'border-none'}}">
                    </ng-container>
                    <ng-container *ngSwitchCase="'VIDEO'">
                        <div (click)="setPreviewURL(idxMedia)" class="relative cursor-pointer rounded">
                            <img (click)="setPreviewURL(idxMedia)" src="{{media.thumb}}" alt="Img {{idxMedia}}" 
                                [id]="'preview_image_' + idxMedia" class="border aspect-square w-[76px] min-w-[76px] md:w-[100px] md:min-w-[100px] rounded 
                                cursor-pointer hover:scale-[1.02] transition-all {{idxMedia === currentIndexPreview ? 'border-white' : 'border-none'}}">
                            <div class="absolute top-0 bottom-0 right-0 left-0 z-[10] flex justify-center items-center 
                                        bg-black bg-opacity-20">
                                <i class="sctr-icon-solid-play text-sm font-semibold text-white"></i>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'FILE'">
                        <img (click)="setPreviewURL(idxMedia)" src="{{media.thumb}}" alt="Img {{idxMedia}}" (error)="replaceWithDefaultImage($event)"
                            [id]="'preview_image_' + idxMedia" class="border aspect-square w-[76px] min-w-[76px] md:w-[100px] md:min-w-[100px] rounded 
                            cursor-pointer hover:scale-[1.02] transition-all {{idxMedia === currentIndexPreview ? 'border-white' : 'border-none'}}">
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <img (click)="setPreviewURL(idxMedia)" src="{{media.url}}" alt="Img {{idxMedia}}" (error)="replaceWithDefaultImage($event)"
                            [id]="'preview_image_' + idxMedia" class="border aspect-square w-[76px] min-w-[76px] md:w-[100px] md:min-w-[100px] rounded 
                            cursor-pointer hover:scale-[1.02] transition-all {{idxMedia === currentIndexPreview ? 'border-white' : 'border-none'}}">
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>