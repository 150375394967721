<div class="mx-[2%] !py-3 flex justify-center">
    <div class="max-w-[1200px] overflow-auto h-full w-full">
        <ng-container *ngIf="isLoading; else detail">
            <div>
                <div class="flex space-x-0 lg:!space-x-6 w-full">
                    <div class="w-full max-w-[792px]">
                        <div class="mb-2 border border-palette-gray-200 shadow-sm bg-white flex p-[7px] items-center rounded-lg text-sm font-semibold">
                            <p-skeleton height="2.25rem" width="7.5rem"></p-skeleton>
                        </div>
                        <div class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                        </div>  
                        <div class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                        </div>  
                        <div class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                            <p-skeleton height="2rem"></p-skeleton>
                        </div>   
                        <div class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="1.5rem"></p-skeleton>
                            <p-skeleton height="1.5rem"></p-skeleton>
                            <p-skeleton height="1.5rem"></p-skeleton>
                            <p-skeleton height="1.5rem"></p-skeleton>
                        </div>                        
                        <div class="w-full min-[1110px]:!hidden block mt-2">
                            <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                                <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                            </div>   
                            <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                                <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                                <p-skeleton height="1.25rem"></p-skeleton>
                            </div>     
                            <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                                <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                                <p-skeleton height="1.75rem"></p-skeleton>
                            </div>  
                        </div>
                    </div>
                    <div class="w-full max-w-[384px] min-[1110px]:!block hidden">
                        <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                        </div>   
                        <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                            <p-skeleton height="1.25rem"></p-skeleton>
                        </div>     
                        <div class="flex flex-col !gap-2 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                            <p-skeleton height="2rem" width="7.5rem"></p-skeleton>
                            <p-skeleton height="1.75rem"></p-skeleton>
                        </div>  
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #detail>
            <ng-container *ngIf="flightDetail == null; else detail">
                <div class="flex flex-col gap-11 items-center justify-center mt-[100px]">
                    <img src="assets/images/flight-view-details/noItem.svg" alt="" class="w-[152px] h-[132px]">
                    <div class="flex justify-center text-palette-gray-900 text-lg font-semibold leading-7 !mb-4">
                      {{ "MY_FLIGHT_BOOKING_DETAIL.NO_RESERVATION_DISPLAY" | translate }}
                    </div>
                  </div>
            </ng-container>
            <ng-template #detail>
                <div>
                    <div class="flex flex-col min-[1110px]:flex-row !gap-6 w-full">
                        <div class="w-full max-w-[792px]">
                            <div
                                class="mb-2 border border-palette-gray-200 shadow-sm bg-white flex p-[7px] items-center rounded-lg text-sm font-semibold">
                                <div (click)="currentModeView = 'departure'"
                                    class="min-w-[52px] rounded-lg h-9 flex justify-center items-center px-3 py-2 mr-2 cursor-pointer"
                                    [ngClass]="currentModeView == 'departure'? 'bg-palette-blue-50 text-branding-primary-600' : 'text-palette-gray-500'">
                                    {{ "FLIGHT.MY_BOOKING.DEPARTURE" | translate}}
                                </div>
                                <div *ngIf="returnTicket" (click)="currentModeView = 'return'"
                                    [ngClass]="currentModeView== 'return'? 'bg-palette-blue-50 text-branding-primary-600': 'text-palette-gray-500'"
                                    class="min-w-[52px] h-9 rounded-lg flex justify-center items-center cursor-pointer px-3 py-2">
                                    {{ "FLIGHT.MY_BOOKING.RETURN" | translate}}
                                </div>
                            </div>
                            <!-- <div *ngIf="currentModeView === 'departure'">
                                <app-view-departure-mode [flightDetail]="flightDetail"></app-view-departure-mode>
                            </div>
                            <div *ngIf="currentModeView === 'return'">
                                <app-view-return-mode [flightDetail]="flightDetail"></app-view-return-mode>
                            </div> -->
                        </div>
                        <div class="flex flex-col gap-2 w-full min-[1100px]:max-w-[384px]">
                            <booking-info [flightDetail]="flightDetail"></booking-info>
                            <contact-info [flightDetail]="flightDetail"></contact-info>
                            <app-price-details [flightDetail]="flightDetail"></app-price-details>
                            <!-- <div class="flex justify-between border border-palette-blue-600 bg-palette-blue-50 !p-4 rounded-lg">
                                <p class="primary-font-bold">{{ "MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.HISTORY" | translate }}</p>
                                <button (click)="onClickViewHistory()" class="primary-font-bold text-palette-blue-600 hover:text-palette-blue-400">
                                    {{ "MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.VIEW_HISTORY" | translate }}
                                </button>
                            </div>  -->
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>

<!-- Popup Change request history -->
<ng-container *ngIf="isShowChangeRequestHistoryPopup">
    <div class="fixed top-0 right-0 bottom-0 left-0 z-[99] flex justify-center items-center bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden">
        <div class="w-[544px] h-auto max-h-[90vh] rounded-xl border border-palette-gray-200 popup-shadow bg-white relative overflow-auto">
            <div class="flex justify-between !px-6 !pt-6">
                <span class="primary-font-bold text-lg">{{ "MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.HISTORY" | translate }}</span>
                <button (click)="closeChangeRequestHistoryPopup()" class="flex justify-center items-center !w-7 !h-7 rounded-full hover:bg-palette-gray-100">
                    <i class="sctr-icon-x-close text-xl leading-5"></i>
                </button>
            </div>
            <div *ngIf="changeRequestHistory?.length" class="flex flex-col gap-2 px-3 pt-2 pb-4 bg-palette-gray-50">
                <ng-container *ngFor="let item of changeRequestHistory; let index = index">
                    <a [href]="pathChangeRequest + '/' + bookingId + '/' + item.id" target="_blank" class="border border-palette-gray-200 rounded-lg bg-white !p-4 cursor-pointer hover:!bg-palette-gray-100 hover:shadow-lg hover:scale-[1.01]">
                        <h3 class="text-sm font-medium text-palette-gray-900 !mb-1">
                            #{{index+1}} {{titleMap[item.title]}}
                        </h3>
                        <p class="text-xs text-palette-gray-600 !mb-1">{{formatDateTime(item.request_at)}}</p>
                        <div class="rounded-full px-[6px] py-[2px] w-fit text-xs font-medium {{changeRequestStatusColor(item.status)}}">
                            {{item.status}}
                        </div>
                    </a>
                </ng-container>
            </div>
            <div *ngIf="!changeRequestHistory?.length" class="flex flex-col items-center !gap-2 py-10">
                <img src="assets/images/flight-view-details/noItem.svg" alt="" class="w-[152px] h-[132px]">
                <p class="text-sm text-palette-gray-700 font-s">{{ "MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.NO_CHANGE" | translate }}</p>
            </div>
        </div>
    </div>
</ng-container>