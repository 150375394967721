import { Component, Input, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MulTranslationService } from 'src/app/core/services/mul-translation/mul-translation.service';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import {
    MAX_TRUNCATED_TEXT_HEIGHT_COMMENT,
    MAX_TRUNCATED_TEXT_HEIGHT,
} from '@shared/constant';
import { TranslateParamModel } from 'src/app/lib/api/translate/api.translate.model';
import { ToastType } from '@shared/enum/toast-type';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Component({
  selector: 'fare-rule',
  templateUrl: './fare-rule.component.html',
  styleUrls: ['./fare-rule.component.scss']
})
export class FareRuleComponent {
  @Input() fareRule: any;
  @Input() fareRuleVi:  any;
  @Input() resource: any;
  @Input() currentSegments: any;
  @Input() showTitle?: boolean = true;
  @Input() noneBorder?: boolean = false;

  textTranslated: string;
  contentHeight = 0;
  isTranslate = false;
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = MAX_TRUNCATED_TEXT_HEIGHT_COMMENT;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  isLiveView = false;
  isEdit = false;
  currentLang= 'en'
  airlineWebsite: any;
  operatingCode: any;

  constructor(
      private messageService: MessageService,
      private mulTranslationService: MulTranslationService,
      private soctripTranslationService: SoctripTranslationService,
      private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.getLinkAirlineWebsite(this.operatingCode);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.getLinkAirlineWebsite(this.operatingCode);
    }) 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentSegments']) {
      this.operatingCode = this.currentSegments[0].operating_code;
      this.getLinkAirlineWebsite(this.operatingCode);
    }
  }

  capitalizeFirstLetterAfterLiTags(string: string) {
    if(string){
      let html = string.replace(/<li\b[^>]*>([^<]*)<\/li>/g, (match, content) => {
        if (!/<\/?[a-z][\s\S]*>/i.test(content.trim())) {
          content = content.charAt(0).toUpperCase() + content.slice(1).toLowerCase();
          return `<li>${content}</li>`;
        }
        return match;
      });
      html = html.replace(/\s{5}(?=\d|-)/g, '<br>&emsp;');
      html = html.replace(/\s{3,}/g, ' ');
      html = html.replace(/-{3,}/g, match => {
        return `<br>${match}<br>`;
    });
      html = html.replace(/\n/g, '<br>');
      html =  html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
      html = html.replace(/(<br\s*\/?>){2,}/g, '<br>');
      return html;
    }
    return '';
  }

  getFareRule(){
    if(this.currentLang == environment.support_languages[1]){
      if(this.fareRuleVi){
        return  this.fareRuleVi
      }else{
        return this.fareRule
      }
      
    }else{
      return this.fareRule
    }
  }

  getLinkAirlineWebsite(code: any) {
    switch (code) {
      case 'VN':
        this.airlineWebsite = this.currentLang === 'en'
          ? 'https://www.vietnamairlines.com/vn/en/buy-tickets-other-products/fare-conditions/fare-types'
          : 'https://www.vietnamairlines.com/vn/vi/buy-tickets-other-products/fare-conditions/fare-types'
        break;
      case 'VJ':
        this.airlineWebsite = this.currentLang === 'en'
          ? 'https://www.vietjetair.com/en/pages/to-have-a-good-flight-1599448842652/fare-rules-1607053453722'
          : 'https://www.vietjetair.com/vi/pages/de-co-chuyen-bay-tot-dep-1578323501979/dieu-kien-ve-1641466500765'
        break;
      case 'QH':
        this.airlineWebsite = this.currentLang === 'en'
          ? 'https://www.bambooairways.com/vn/en/book/booking-information/fare-rules/domestic-routes'
          : 'https://www.bambooairways.com/vn/vi/book/booking-information/fare-rules/domestic-routes'
        break;
      case 'VU':
        this.airlineWebsite = this.currentLang === 'en'
          ? 'https://www.vietravelairlines.com/vn/en/booking/booking-guidance/fare-rules'
          : 'https://www.vietravelairlines.com/vn/vi/thong-tin-dat-ve/dat-ve-may-bay-va-huong-dan/dieu-kien-gia-ve'
        break;
      case 'BL':
        this.airlineWebsite = this.currentLang === 'en'
          ? 'https://www.vietnamairlines.com/us/en/buy-tickets-other-products/fare-conditions/fare-types/fare-DOM-eco'
          : 'https://www.vietnamairlines.com/us/vi/buy-tickets-other-products/fare-conditions/fare-types/fare-DOM-buz'
        break;
      default:
        break;
    }
  }

  onTranslateText() {
      this.textTranslated = '';
      const selectedLangCode = this.mulTranslationService
          .getKeyLocalUserProfile('language')
          ?.toLowerCase();
      this.isTranslate = !this.isTranslate;
      this.getTranslateText(selectedLangCode?.toLowerCase() || 'en');
      if (this.contentHeight > MAX_TRUNCATED_TEXT_HEIGHT) {
          this.isShowAllContent = true;
      }
  }

  getTranslateText(target: string) {
      let content = this.fareRule;
      content = this.capitalizeFirstLetterAfterLiTags(content);
      const params: TranslateParamModel = {
          q: content.trim(),
          source: 'auto', // TODO: Implement dectect language when `comment.language` is implemented
          target: target,
          format: 'html',
      };
      this.mulTranslationService.translateText(params).subscribe((res: any) => {
          if (res && res.success) {
              this.textTranslated = res?.data.translatedText;
          } else {
              this.isTranslate = false;
              this.messageService.add({
                  severity: ToastType.SUCCESS,
                  detail: this.soctripTranslationService.getTranslation('TRANSLATION.TRANSLATE_FAILED'),
              });
          }
      });
  }

  onExpandText() {
      this.isShowAllContent = !this.isShowAllContent;
  }

  markHashtagsForTranslation(content: string) {
      let tempConent = content;
      tempConent = tempConent?.replace(/\n/g, '<br />');
      return tempConent;
  }

  formattedContent() {
      let content = this.fareRule;
      content = this.capitalizeFirstLetterAfterLiTags(content);
      content = this.replaceLinkTag(content);
      content = this.mulTranslationService.urlify(content);
      return content;
  }

  replaceLinkTag(content: string) {
      let tempConent = content;
      return tempConent;
  }

  handleChangeLanguage(event: any) {
      this.textTranslated = '';
      this.getTranslateText(event.value.code);
  }

  translateContent() {
      return this.textTranslated;
  }

  isShowTranslateButton(fareRule: any) {
      if (!this.formattedContent()) return false;
      return this.mulTranslationService.checkLanguage(
          fareRule,
          'en'
      );
  }
}
