<div class="!mt-2 border-1 border-palette-gray-200 rounded-lg bg-white !p-4 font-inter">
    <div (click)="handleClickExpandCollapse(expandMeal)" (mouseover)="handleMouseOverLeave(expandMeal, true)"
        (mouseleave)="handleMouseOverLeave(expandMeal, false)" class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center !gap-4">
            <img class="!h-[56px] aspect-square" src="assets/images/flight-payment/meal.svg" alt="">
            <p [ngClass]="{'!font-extrabold':expandMeal.isMouseOver}"
                class="text-lg font-semibold text-palette-gray-900">
                {{'FLIGHT.PAYMENT.MEAL_SELECTION' | translate}}</p>
        </div>
        <i [ngClass]="{'sctr-icon-chevron-down':expandMeal.isExpand, 'sctr-icon-chevron-right':!expandMeal.isExpand, 'font-extrabold':expandMeal.isMouseOver}"
            class="text-xl leading-5 text-palette-gray-500"></i>
    </div>
    <div @slideDownUp *ngIf="expandMeal.isExpand"
        class="{{mealsSummary.length > 1 ? '!grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4' : ''}} !mt-4">
        <ng-container *ngIf="mealsSummary.length; else mealNotIncluded">
            <ng-container *ngFor="let mealsSegment of mealsSummary">
                <div class="!p-3 border border-palette-gray-200 bg-palette-gray-50 rounded-md">

                    <div class="flex items-center !gap-2 text-base !font-medium text-palette-gray-900">
                        <span>{{ mealsSegment?.origin_iata_code }}</span>
                        <i class="sctr-icon-arrow-narrow-right text-[20px]"></i>
                        <span>{{ mealsSegment?.destination_iata_code }}</span>
                    </div>
                    <ng-container *ngIf="mealsSegment?.passengers && mealsSegment?.passengers.length >0">
                        <ng-container *ngFor="let passenger of mealsSegment?.passengers">
                            <div class="flex justify-between relative mt-2 space-x-2">
                                <div [tooltip]=" ('COMMON.' + passenger?.title.toUpperCase() | translate) + ' ' + toFirstUppercase(passenger.last_name) + ' ' + toFirstUppercase(passenger.middle_and_first_name)"
                                    [isTruncated]="true"
                                    class="w-2/5 text-palette-gray-700 text-sm line-clamp-1 max-h-[20px] capitalize">
                                    {{ 'COMMON.' + passenger?.title?.toUpperCase() | translate }} {{ passenger?.last_name | lowercase }} {{ passenger?.middle_and_first_name | lowercase }}
                                </div>
                                <div class="w-3/5 text-palette-gray-900 font-medium text-sm">
                                    <ng-container *ngIf="passenger?.meals and passenger?.meals.length > 0">
                                        <ng-container *ngFor="let meal of passenger?.meals">
                                            <div class=" flex justify-end">
                                                <div class="relative">
                                                    <div [tooltip]="meal.meal_name" [isTruncated]="true"
                                                        [theme]="TooltipTheme.LIGHT" [position]="TooltipPosition.ABOVE"
                                                        [styleClass]="''" class="line-clamp-1 max-h-[20px] font-medium">
                                                        {{meal.meal_name}}</div>
                                                </div>
                                                <p class="flex-nowrap whitespace-nowrap ml-1 font-medium">{{' x '+
                                                    meal.quantity}}</p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #mealNotIncluded>
            <div class="text-palette-gray-700 text-sm font-semibold">Not Included</div>
        </ng-template>
    </div>
</div>