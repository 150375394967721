<div class="min-w-[600px] max-w-[1200px] min-[1200px]:!mx-auto !mx-2 sm:!mx-6 font-inter bg-white">
    <!-- HEADER -->
    <div class="rounded-lg h-[96px] flex items-center !px-6 bg-palette-blue-600">
        <div class="flex gap-2">
            <!-- <img src="assets/images/logo/white-logo-text.svg" alt="" class="min-w-[125px] max-h-[30px] min-h-[30px]"> -->
            <img src="assets/images/logo/Soctrip-logo.svg" alt="" class="min-w-[125px] max-h-[30px] min-h-[30px]">
            <div class="text-2xl font-bold text-white">E-Ticket</div>
        </div>
    </div>
    <!-- BODY -->
    <div class="!px-4 !py-6">
        <ng-container *ngIf="!isLoading; else skeleton">
            <div class="w-full flex justify-end !px-6 !mb-2 text-sm text-palette-gray-700">
                Issued date: {{ dateIssue | dateFormat : true }}
            </div>
            <div class="!px-6 grid grid-cols-1 sm:grid-cols-2 min-[900px]:grid-cols-3 justify-center gap-3 min-[1200px]:!gap-6 m-0">
                <!-- SUMMARY SEGMENTS -->
                <div class="col-span-1 !flex !gap-6">
                    <div class="!flex flex-col !gap-4 justify-between">
                        <div class="text-right">
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{ getTime(segments[0]?.time_departure_at) || '--' }}</p>
                            <p class="text-xs text-palette-gray-500 !mt-1 whitespace-nowrap">
                                {{ (segments[0]?.date_departure_at | dateFormat) || '--' }}</p>
                        </div>
                        <div>
                            <!-- <img 
                                *ngIf="item.operating_logo_id"
                                loading="lazy"
                                class="rounded-md max-w-[70px] max-h-[30px]"
                                src="{{ baseImage }}/{{ item.operating_logo_id }}.webp"
                                (error)="replaceWithDefaultImage($event)" alt="">
                            <img 
                                *ngIf="!item.operating_logo_id"
                                loading="lazy"
                                class="rounded-md max-w-[70px] max-h-[30px]"
                                [src]="item.operating_logo_url"
                                (error)="replaceWithDefaultImage($event)"
                                alt=""
                            > -->
                        </div>
                        <div class="text-right">
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{ getTime(segments[segments.length-1]?.time_arrival_at) || '--' }}</p>
                            <p class="text-xs text-palette-gray-500 !mt-1 whitespace-nowrap">
                                {{ (segments[segments.length-1]?.date_arrival_at | dateFormat) || '--' }}</p>
                        </div>
                    </div>
                    <div class="!flex flex-col items-center">
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                        <div class="h-full border border-dashed w-[1px]"></div>
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                    </div>
                    <div class="!flex flex-col justify-between !gap-4">
                        <div>
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{ segments[0]?.departure?.city_name || '--' }} ({{ segments[0]?.departure?.iata_code || '--' }})</p>
                            <p class="text-xs text-palette-gray-500">
                                {{ segments[0]?.departure?.airport_name || '--' }} {{ segments[0]?.departure?.terminal || '--' }}</p>
                        </div>
                        <div class="text-sm text-palette-gray-700">
                            {{ segments.length }} {{ segments.length > 1 ? 'stops' : 'stop' }} • {{formatDuration('01:05', true)}}
                        </div>
                        <div>
                            <p class="text-sm font-medium text-palette-gray-700">
                                {{ segments[0]?.arrival?.city_name || '--' }} ({{ segments[0]?.arrival?.iata_code || '--' }})</p>
                            <p class="text-xs text-palette-gray-500">
                                {{ segments[segments.length-1]?.departure?.airport_name || '--' }} {{ segments[segments.length-1]?.departure?.terminal || '--' }}</p>
                        </div>
                    </div>
                </div>
                <!-- CONTACT INFORMATION -->
                <div class="col-span-1">
                    <h3 class="text-palette-gray-900 text-base font-semibold !mb-2">
                        Contact information</h3>
                    <div class="flex flex-col !gap-2">
                        <div class="flex justify-between gap-1">
                            <h4 class="text-palette-gray-500 text-sm font-medium leading-5">
                                Contact name
                            </h4>
                            <p class="text-palette-gray-700 text-sm font-medium leading-5">
                                Mr. Hoang Duy Minh
                                <!-- {{flightDetail?.contact_information.title}}. {{flightDetail?.contact_information.full_name}} -->
                            </p>
                        </div>
                        <div class="flex justify-between gap-1">
                            <h4 class="text-palette-gray-500 text-sm font-medium leading-5">
                                Email
                            </h4>
                            <p class="text-palette-gray-700 text-sm font-medium leading-5">
                                hdminh11/gmail.com
                                <!-- {{flightDetail?.contact_information.email}} -->
                            </p>
                        </div>
                        <div class="flex justify-between gap-1">
                            <h4 class="text-palette-gray-500 text-sm font-medium leading-5">
                                Phone number
                            </h4>
                            <p class="text-palette-gray-700 text-sm font-medium leading-5">
                                (+84) 365924762
                                <!-- ({{flightDetail?.contact_information.phone.phone_area_code}}) {{flightDetail?.contact_information.phone.phone_number}} -->
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-1">
                    <h3 class="text-palette-gray-700 text-sm font-bold">Airline Booking Code (PNR)</h3>
                    <p class="text-palette-gray-900 text-sm font-medium">{{ previewData?.pnr_code || '--' }}</p>
                    <h3 class="text-palette-gray-700 text-sm font-bold !mt-1">Order ID</h3>
                    <p class="text-palette-gray-900 text-sm font-medium">{{ reservationCode }}</p>
                </div>
            </div>
            <hr class="!mt-4 !mb-6">
            <!-- SEGMENTS -->
            <div class="flex flex-col gap-6 !mb-6">
                <div *ngFor="let item of segments">
                    <!-- TITLE -->
                    <div class="grid grid-cols-2 min-[1100px]:grid-cols-3 gap-1 m-0 rounded !p-1 bg-palette-blue-600">
                        <div class="col-span-2 flex items-center gap-2">
                            <img src="assets/images/booking_issue/flight-white.svg" alt="" class="!w-6 !h-6">
                            <div class="flex flex-col sm:flex-row sm:items-center">
                                <h3 class="text-white text-base uppercase">
                                    DEPARTURE: 
                                    <span class="font-semibold">{{ (item.date_departure_at | dateFormat) || '--' }}</span>
                                </h3>
                                <i class="hidden sm:block sctr-icon-chevron-right text-base leading-4 !mx-2 md:!mx-10 text-white"></i>
                                <h3 class="text-white text-base uppercase">
                                    ARRIVAL: 
                                    <span class="font-semibold">{{ (item.date_arrival_at | dateFormat) || '--' }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="col-span-2 min-[1100px]:col-span-1 text-white text-sm">
                            Please verify flight times prior to departure
                        </div>
                    </div>
                    <!-- SEGMENT DETAILS -->
                    <div class="grid grid-cols-3 m-0 !mt-3 rounded-md border">
                        <div class="col-span-3 md:col-span-1 !p-3 border-r bg-palette-gray-50">
                            <div class="flex items-center !gap-1 !mb-3">
                                <div>
                                    <!-- <img 
                                        *ngIf="item.operating_logo_id"
                                        loading="lazy"
                                        class="rounded-md max-w-[70px] max-h-[30px]"
                                        src="{{ baseImage }}/{{ item.operating_logo_id }}.webp"
                                        (error)="replaceWithDefaultImage($event)" alt="">
                                    <img 
                                        *ngIf="!item.operating_logo_id"
                                        loading="lazy"
                                        class="rounded-md max-w-[70px] max-h-[30px]"
                                        [src]="item.operating_logo_url"
                                        (error)="replaceWithDefaultImage($event)"
                                        alt=""
                                    > -->
                                </div>
                                <div class="text-base text-palette-gray-700 uppercase">
                                    {{ item?.baggage_allowance?.airline_name || '--' }}
                                </div>
                            </div>
                            <div class="">
                                <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                    <h4 class="text-palette-gray-700 text-sm">
                                        Flight No.
                                    </h4>
                                    <p class="text-palette-gray-900 text-sm font-medium">{{ item?.flight_no || '--' }}</p>
                                </div>
                                <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                    <h4 class="text-palette-gray-700 text-sm">
                                        Aircraft
                                    </h4>
                                    <p class="text-palette-gray-900 text-sm font-medium">{{ item?.aircraft || '--' }}</p>
                                </div>
                                <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                    <h4 class="text-palette-gray-700 text-sm">
                                        Duration
                                    </h4>
                                    <p class="text-palette-gray-900 text-sm font-medium">
                                        {{ formatDuration(getTime(item?.duration), true) || '--' }}</p>
                                </div>
                                <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                    <h4 class="text-palette-gray-700 text-sm">
                                        Class
                                    </h4>
                                    <p class="text-palette-gray-900 text-sm font-medium">
                                        {{ item?.travel_class || '--' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-3 md:col-span-2">
                            <div class="border-b grid grid-cols-2 gap-4 m-0 !p-3">
                                <div class="col-span-1 flex gap-2 justify-between items-center">
                                    <div class="flex flex-col gap-1">
                                        <p class="text-palette-gray-900 text-base font-semibold uppercase">
                                            {{ item?.departure?.iata_code || '--' }}</p>
                                        <p class="text-palette-gray-700 text-base uppercase">{{ item?.departure?.city_name || '--' }}</p>
                                    </div>
                                    <img src="assets/images/booking_issue/flight-blue.svg" alt="" class="!w-6 !h-6 !mr-12 md:!mr-6 lg:!mr-16">
                                </div>
                                <div class="col-span-1 flex flex-col gap-1">
                                    <p class="text-palette-gray-900 text-base font-semibold uppercase">
                                        {{ item?.arrival?.iata_code || '--' }}
                                    </p>
                                    <p class="text-palette-gray-700 text-base uppercase">{{ item?.arrival?.city_name || '--' }}</p>
                                </div>
                            </div>
                            <div class="grid min-[980px]:grid-cols-2 gap-4 m-0 !p-3">
                                <div class="col-span-1 flex gap-3 items-center">
                                    <div class="min-w-[100px]">
                                        <p class="text-palette-gray-700 text-sm">Departure at</p>
                                        <p class="text-palette-gray-700 text-sm">Terminal</p>
                                    </div>
                                    <div>
                                        <p class="text-palette-gray-900 text-sm font-medium whitespace-nowrap truncate">
                                            {{ getTime(item?.date_departure_at) || '--' }} ({{ (item?.date_departure_at | dateFormat) || '--' }})
                                        </p>
                                        <p class="text-palette-gray-900 text-sm font-medium">
                                            Terminal {{ item?.departure?.terminal }}</p>
                                    </div>
                                </div>
                                <div class="col-span-1 flex gap-3 items-center">
                                    <div class="min-w-[100px]">
                                        <p class="text-palette-gray-700 text-sm">Arrival</p>
                                        <p class="text-palette-gray-700 text-sm">Terminal</p>
                                    </div>
                                    <div>
                                        <p class="text-palette-gray-900 text-sm font-medium whitespace-nowrap">
                                            {{ getTime(item?.time_arrival_at) || '--' }} 
                                            <span *ngIf="getDate(item?.date_departure_at) != getDate(item?.date_arrival_at)">
                                                ({{ (item?.date_arrival_at | dateFormat) || '--' }})
                                            </span>
                                        </p>
                                        <p class="text-palette-gray-900 text-sm font-medium">
                                            Terminal {{ item?.arrival?.terminal }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- TABLE PASSENGER -->
                    <!-- <div class="relative overflow-x-auto !mt-2 border !rounded-lg border-palette-gray-200 !font-medium">
                        <table class="min-w-full">
                            <thead
                                class="text-sm leading-[39.5px] text-palette-gray-700 !rounded-t-lg bg-palette-gray-100">
                                <tr class="border-b">
                                    <td class="rounded-tl-lg sticky left-0 z-10 bg-palette-gray-100 whitespace-nowrap">{{ 'FLIGHT.PAYMENT.PASSENGER' | translate }}</td>
                                    <td class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.SEAT' | translate }}</td>
                                    <td class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate }}</td>
                                    <td class="rounded-tr-lg whitespace-nowrap">{{ 'FLIGHT.PAYMENT.MEAL' | translate }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let passenger of passengers; let last = last">
                                    <tr class="text-xs leading-5 text-palette-gray-700" [class.border-b]="!last">
                                        <td class="py-1 sticky left-0 z-10 bg-white whitespace-nowrap">
                                            <div class="flex flex-row items-center !gap-1">
                                                <p class="font-medium">{{ ('COMMON.' + (passenger.title | uppercase)) | translate }} 
                                                    <span class="uppercase">{{ passenger.middle_and_first_name }} {{ passenger.last_name }}</span>
                                                </p>
                                            </div>
                                            <p class="text-palette-gray-500 font-normal">
                                                {{ 'FLIGHT.PAYMENT.' + passenger.passenger_type.toUpperCase() | translate }}
                                            </p>
                                        </td>
                                        <td class="py-1 w-fit">
                                            <ng-container *ngIf="passenger[key+'_seat']?.length; else noSeat">
                                                <div *ngFor="let segment of passenger[key+'_seat']; let lastItem = last" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                                    <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                                        {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                                    </p>
                                                    <p class="text-palette-gray-500 whitespace-nowrap">{{ segment.code }}</p>
                                                </div>
                                            </ng-container>
                                            <ng-template #noSeat>
                                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                                            </ng-template>
                                        </td>
                                        <td class="py-1 w-fit">
                                            <ng-container *ngIf="passenger[key+'_extra_baggage']?.length; else noBaggage">
                                                <div *ngFor="let segment of passenger[key+'_extra_baggage']; let lastItem = last" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                                    <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                                        {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                                    </p>
                                                    <p class="text-palette-gray-500 whitespace-nowrap">
                                                        {{ segment.weight }} {{ segment.unit }}
                                                    </p>
                                                </div>
                                            </ng-container>
                                            <ng-template #noBaggage>
                                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                                            </ng-template>
                                        </td>
                                        <td class="py-1 w-fit">
                                            <ng-container *ngIf="passenger[key+'_meals']?.length; else noMeal">
                                                <ng-container *ngFor="let segment of passenger[key+'_meals']; let lastItem = last">
                                                    <div *ngIf="segment.meals.length" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                                        <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                                            {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                                        </p>
                                                        <p *ngFor="let meal of segment.meals; let last = last" class="text-palette-gray-500 font-normal whitespace-nowrap">
                                                            {{ meal.meal_name }} x{{ meal.quantity }}<span *ngIf="!last">, </span>
                                                        </p>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noMeal>
                                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
            <!-- NOTES -->
            <div>
                <h3 class="text-palette-gray-900 text-xl font-semibold !mb-3">Notes</h3>
                <div class="flex flex-col gap-1">
                    <p class="text-palette-gray-700 text-sm">
                        <span class="!mr-1 text-palette-gray-400">•</span> 
                        Please ensure passport expiry date must have minimum 6 months validity from departure date and check Visa requirements - if applicable
                    </p>
                    <p class="text-palette-gray-700 text-sm">
                        <span class="!mr-1 text-palette-gray-400">•</span> 
                        Please check all the migratory requirements prior your trip Visas vaccines.
                    </p>
                    <p class="text-palette-gray-700 text-sm">
                        <span class="!mr-1 text-palette-gray-400">•</span> 
                        E-ticket receipt required to clear security.
                    </p>
                    <p class="text-palette-gray-700 text-sm">
                        <span class="!mr-1 text-palette-gray-400">•</span> 
                        Traveler may be required to show photo ID.
                    </p>
                    <p class="text-palette-gray-700 text-sm">
                        <span class="!mr-1 text-palette-gray-400">•</span> 
                        Airlines check-in opens 3 hours before departure for international flights and 2 hours before departure for domestic flights.
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-template #skeleton>
            <div class="w-full flex justify-end !px-6 !mb-2 text-sm text-palette-gray-700">
                <p-skeleton height="1.2rem" />
            </div>
            <div class="!px-6 grid grid-cols-1 sm:grid-cols-2 min-[900px]:grid-cols-3 justify-center gap-3 min-[1200px]:!gap-6 m-0">
                <!-- SUMMARY SEGMENTS -->
                <div class="col-span-1 !flex !gap-6">
                    <div class="!flex flex-col !gap-4 items-end">
                        <div class="flex flex-col items-end gap-1">
                            <p-skeleton height="1.2rem" width="3rem"/>
                            <p-skeleton height="1.2rem" width="5rem" />
                        </div>
                        <p-skeleton height="1.2rem" width="4rem" />
                        <div class="flex flex-col items-end gap-1">
                            <p-skeleton height="1.2rem" width="3rem"/>
                            <p-skeleton height="1.2rem" width="5rem" />
                        </div>
                    </div>
                    <div class="!flex flex-col items-center">
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                        <div class="h-full border border-dashed w-[1px]"></div>
                        <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                    </div>
                    <div class="!flex flex-col justify-between !gap-4">
                        <div class="flex flex-col gap-1">
                            <p-skeleton height="1.2rem" width="6rem" />
                            <p-skeleton height="1.2rem" width="9rem" />
                        </div>
                        <div class="text-sm text-palette-gray-700">
                            <p-skeleton height="1.2rem" width="3rem" />
                        </div>
                        <div class="flex flex-col gap-1">
                            <p-skeleton height="1.2rem" width="6rem" />
                            <p-skeleton height="1.2rem" width="9rem" />
                        </div>
                    </div>
                </div>
                <!-- CONTACT INFORMATION -->
                <div class="col-span-1">
                    <p-skeleton height="1.2rem" width="6rem" styleClass="!mb-2"/>
                    <div class="flex flex-col !gap-2">
                        <div class="flex justify-between gap-1">
                            <p-skeleton height="1.2rem" width="4rem" />
                            <p-skeleton height="1.2rem" width="6rem" />
                        </div>
                        <div class="flex justify-between gap-1">
                            <p-skeleton height="1.2rem" width="4rem" />
                            <p-skeleton height="1.2rem" width="10rem" />
                        </div>
                        <div class="flex justify-between gap-1">
                            <p-skeleton height="1.2rem" width="6rem" />
                            <p-skeleton height="1.2rem" width="6rem" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-1">
                    <p-skeleton height="1.2rem" width="16rem" />
                    <p-skeleton height="1.2rem" width="10rem" />
                    <p-skeleton height="1.2rem" width="7rem" />
                    <p-skeleton height="1.2rem" width="6rem" />
                </div>
            </div>
            <hr class="!mt-4 !mb-6">
            <!-- SEGMENTS -->
            <div class="flex flex-col gap-6 !mb-6">
                <ng-container *ngFor="let item of [1]">
                    <div class="">
                        <!-- TITLE -->
                        <div class="grid grid-cols-2 min-[1100px]:grid-cols-3 gap-1 m-0 rounded !p-1 bg-palette-blue-600">
                            <div class="col-span-2 flex items-center gap-2">
                                <p-skeleton height="1.2rem" width="20rem" />
                            </div>
                            <p-skeleton height="1.2rem" width="12rem" />

                        </div>
                        <!-- SEGMENT DETAILS -->
                        <div class="grid grid-cols-3 m-0 !mt-3 rounded-md border">
                            <div class="col-span-3 md:col-span-1 !p-3 border-r bg-palette-gray-50">
                                <div class="flex items-center !gap-1 !mb-3">
                                    <p-skeleton height="1.2rem" width="6rem" />
                                </div>
                                <div class="">
                                    <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                        <p-skeleton height="1.2rem" width="5rem" />
                                        <p-skeleton height="1.2rem" width="7rem" />
                                    </div>
                                    <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                        <p-skeleton height="1.2rem" width="5rem" />
                                        <p-skeleton height="1.2rem" width="4rem" />
                                    </div>
                                    <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                        <p-skeleton height="1.2rem" width="5rem" />
                                        <p-skeleton height="1.2rem" width="7rem" />
                                    </div>
                                    <div class="grid grid-cols-2 gap-0 min-[1024px]:gap-2 m-0 !mb-2">
                                        <p-skeleton height="1.2rem" width="5rem" />
                                        <p-skeleton height="1.2rem" width="6rem" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-3 md:col-span-2">
                                <div class="border-b grid grid-cols-2 gap-4 m-0 !p-3">
                                    <div class="col-span-1 flex gap-2 justify-between items-center">
                                        <div class="flex flex-col gap-1">
                                            <p-skeleton height="1.2rem" width="5rem" />
                                            <p-skeleton height="1.2rem" width="9rem" />
                                        </div>
                                        <p-skeleton height="1.2rem" width="2rem" />
                                    </div>
                                    <div class="col-span-1 flex flex-col gap-1">
                                        <p-skeleton height="1.2rem" width="5rem" />
                                            <p-skeleton height="1.2rem" width="9rem" />
                                    </div>
                                </div>
                                <div class="grid min-[980px]:grid-cols-2 gap-4 m-0 !p-3">
                                    <div class="col-span-1 flex gap-3 items-center">
                                        <div class="min-w-[100px]">
                                            <p-skeleton height="1.2rem" width="7rem" styleClass="!mb-1"/>
                                            <p-skeleton height="1.2rem" width="5rem" />
                                        </div>
                                        <div>
                                            <p-skeleton height="1.2rem" width="8rem" styleClass="!mb-1"/>
                                            <p-skeleton height="1.2rem" width="6rem" />
                                        </div>
                                    </div>
                                    <div class="col-span-1 flex gap-3 items-center">
                                        <div class="min-w-[100px]">
                                            <p-skeleton height="1.2rem" width="5rem" styleClass="!mb-1"/>
                                            <p-skeleton height="1.2rem" width="6rem" />
                                        </div>
                                        <div>
                                            <p-skeleton height="1.2rem" width="8rem" styleClass="!mb-1"/>
                                            <p-skeleton height="1.2rem" width="6rem" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- TABLE PASSENGER -->
                    </div>
                </ng-container>
            </div>
            <!-- NOTES -->
            <div>
                <p-skeleton height="1.2rem" width="5rem" />
                <div class="flex flex-col gap-1">
                    <p-skeleton height="1.2rem" width="30rem" />
                    <p-skeleton height="1.2rem" width="20rem" />
                    <p-skeleton height="1.2rem" width="13rem" />
                    <p-skeleton height="1.2rem" width="14rem" />
                    <p-skeleton height="1.2rem" width="18rem" />
                </div>
            </div>
        </ng-template>
    </div>
    <!-- FOOTER -->
    <div class="!p-6 bg-palette-blue-50 flex flex-col justify-center items-center">
        <div class="flex !mb-4">
            <img src="assets/images/booking_issue/blue-logo-soctrip.svg" alt="Logo" class="min-w-[100px] max-h-[30px] min-h-[24px]">
        </div>
        <p class="text-palette-gray-500 text-xs">© {{currentYear}} Soctrip. All rights reserved.</p>
        <p class="text-palette-gray-500 text-xs">{{'contact@soctrip.com'}}  •  (+1) 402-580-7162</p>
    </div>
</div>