<div
    class="my-price-details-section w-full min-[1100px]:w-[384px] !p-4 bg-palette-base-white border border-palette-gray-200 rounded-lg font-inter">
    <p class="text-palette-gray-900 text-base font-semibold !mb-3">
        {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.TITLE' | translate}}</p>
    <div class="flex flex-col !gap-3">
        <div class="flex justify-between">
            <span class="text-palette-gray-500 text-sm font-medium">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.CONTACT_NAME' | translate}}
            </span>
            <div class="relative flex-1 text-end">
                <div class="line-clamp-1 text-sm font-medium text-palette-gray-700 max-w-full capitalize"
                    [isTruncated]="true"
                    [tooltip]="('COMMON.' + flightDetail?.contact_information?.title?.toUpperCase() | translate) + ' ' + toFirstUppercase(flightDetail?.contact_information?.full_name)">
                    {{ 'COMMON.' + flightDetail?.contact_information?.title?.toUpperCase() | translate }} {{ flightDetail?.contact_information?.full_name | lowercase }}
                </div>
            </div>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 text-sm font-medium">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.EMAIL' | translate}}
            </span>
            <span
                class="text-palette-gray-700 text-sm font-medium">{{flightDetail?.contact_information.email}}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 text-sm font-medium">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.PHONE_NUMBER' | translate}}
            </span>
            <span
                class="text-palette-gray-700 text-sm font-medium">({{flightDetail?.contact_information.phone.phone_area_code}})
                {{flightDetail?.contact_information.phone.phone_number}}</span>
        </div>
    </div>
</div>