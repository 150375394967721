import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DateFilter, DateFilterType } from 'src/app/core/enum/date-filter.enum';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
  protected readonly SoctripIcons = SoctripIcons;
  @Input() rangeDates: (Date | undefined)[] = [];
  @Input() dateFilterType: DateFilterType = DateFilterType.THIS_YEAR
  @Input() firstEventEmitter: boolean = true;
  @Output() rangeDatesChange = new EventEmitter<(Date | undefined)[]>();
  @Output() onApply = new EventEmitter();
  
  DFActive?: DateFilterType;
  DF = DateFilter;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  translatedCalendar: any;
  isSpecial: boolean = true;

  constructor(private translateService: TranslateService, private config: PrimeNGConfig) {}

  ngOnChanges() {
    if (this.rangeDates[0] && this.rangeDates[1]) {
      this.dateFrom = this.rangeDates[0];
      this.dateTo = this.rangeDates[1];
    }
  }

  ngOnInit(): void {
    this.filterDate({ value: DateFilterType.ALL_TIME });
    if(this.firstEventEmitter) {
      this.onApply.emit(this.DFActive);
      this.rangeDatesChange.emit(this.rangeDates);
    }
    this.setTranslateCalendar();
  }

  get dateString() {
    if (this.DFActive === DateFilterType.ALL_TIME && this.isSpecial) {
      return 'All time';
    }
    if (!this.rangeDates[0] && !this.rangeDates[1]) return '';
    return [
      this.rangeDates[0] ? moment(this.rangeDates[0]).format('DD/MM/YYYY') : '',
      this.rangeDates[1] ? moment(this.rangeDates[1]).format('DD/MM/YYYY') : '',
    ]
      .filter((item) => item)
      .join(' - ');
  }


  setTranslateCalendar() {
    this.translatedCalendar = {
      dayNames: this.translateService.instant('PRIMENG.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('PRIMENG.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('PRIMENG.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('PRIMENG.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant('PRIMENG.MONTH_NAMES_SHORT'),
      today: this.translateService.instant('PRIMENG.TODAY'),
      weekHeader: this.translateService.instant('PRIMENG.WEEK_HEADER')
    };    
    this.config.setTranslation(this.translatedCalendar);
  }

  filterDate(item: { value: DateFilterType }) {
    let date1, date2: Date | undefined;
    const oneDayQuantity = 1000 * 60 * 60 * 24;
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();

    switch (item.value) {
      case DateFilterType.TODAY:
        date1 = new Date();
        date2 = new Date();
        break;
      case DateFilterType.YESTERDAY:
        date1 = new Date(new Date().getTime() - oneDayQuantity);
        date2 = new Date(new Date().getTime() - oneDayQuantity);
        break;
      case DateFilterType.THIS_WEEK:
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(
          currentDate.getDate() -
            (currentDayOfWeek - 1 + (currentDayOfWeek === 0 ? 7 : 0))
        );

        const lastDayOfWeek = new Date(currentDate);
        lastDayOfWeek.setDate(
          currentDate.getDate() + (7 - (currentDayOfWeek === 0 ? 7 : 0))
        );

        date1 = firstDayOfWeek;
        date2 = lastDayOfWeek;

        break;
      case DateFilterType.LAST_WEEK:
        const firstDayOfLastWeek = new Date(currentDate);
        const daysToSubtract =
          (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) + 7;
        firstDayOfLastWeek.setDate(currentDate.getDate() - daysToSubtract);

        const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
        lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);

        date1 = firstDayOfLastWeek;
        date2 = lastDayOfLastWeek;
        break;
      case DateFilterType.THIS_MONTH:
        date1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        date2 = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );

        break;
      case DateFilterType.LAST_MONTH:
        const firstDayOfCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        firstDayOfPreviousMonth.setMonth(
          firstDayOfPreviousMonth.getMonth() - 1
        );

        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfPreviousMonth.setDate(0);
        date1 = new Date(firstDayOfPreviousMonth);
        date2 = new Date(lastDayOfPreviousMonth);
        break;
      case DateFilterType.THIS_YEAR:
        date1 = new Date(currentDate.getFullYear(), 0, 1);
        date2 = new Date(currentDate.getFullYear(), 11, 31);
        break;
      case DateFilterType.LAST_YEAR:
        date1 = new Date(currentDate.getFullYear() - 1, 0, 1);
        date2 = new Date(currentDate.getFullYear() - 1, 11, 31);

        break;
      case DateFilterType.ALL_TIME:
        date1 = new Date();
        date2 = new Date();
        break;
    }
    this.isSpecial = true;
    this.rangeDates = [date1, date2];
    [this.dateFrom, this.dateTo] = [date1, date2];
    this.DFActive = item.value;
  }

  getDateFilterArray(dateArr: Date[] | undefined[]) {
    this.isSpecial = false;
    [this.dateFrom, this.dateTo] = dateArr;
  }

  setDateFilter(event: any = undefined) {
    if (!this.dateFrom && !this.dateTo) return;
    if (!this.dateFrom) {
      this.dateFrom = this.dateTo;
      this.dateTo = undefined;
    }
    if (this.dateTo! < this.dateFrom!) this.dateTo = undefined;
    this.rangeDates = [this.dateFrom || this.dateTo, this.dateTo];
  }

  cancelDateFilter(op?: OverlayPanel) {
    if (op) {
      op.hide();
      return;
    }
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.rangeDates = [];
  }
}
