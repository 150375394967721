import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { TooltipPosition } from '@shared/enum/tooltip.enums';

@Component({
    selector: 'app-popup-cancel-booking',
    templateUrl: './popup-cancel-booking.component.html',
    styleUrls: ['./popup-cancel-booking.component.scss'],
})
export class PopupCancelBookingComponent {
    @Input() flightDetail: any;
    @Input() selectedPassenger: any;
    @Output() backToStatusNote = new EventEmitter<any>();
    @Output() confirmCancel = new EventEmitter<any>();

    TooltipPosition: typeof TooltipPosition = TooltipPosition;
    cancelForm: FormGroup;
    collapsedStates: boolean[] = [];
    isShowPopupDiscard: boolean = false;
    isShowPopupConfirm: boolean = false;
    sectionVisibility: any = [];
    fareRule: string;
    currentFareRule: number = 0;
    itineraries: any;
    priceDetails: any;
    baseImage = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.cancelForm = this.fb.group({
            isRefund: [null, [Validators.required]],
        });
        // Initialize collapsed states
        this.flightDetail?.flight_info?.itineraries.forEach((itinerary: any, index: number) => {
            this.sectionVisibility[index] = {
                state: false,
                tickets: false,
                taxes: false,
                addon: false,
                priceTickets: false,
                priceAddon: false
            };
        });
        this.itineraries = this.flightDetail?.flight_info?.itineraries;
        this.priceDetails = this.flightDetail?.price_details;
        this.fareRule = this.itineraries[0]?.fare_rule;
    }

    get f(): { [key: string]: AbstractControl } {
        return this.cancelForm.controls;
    }

    toggleSection(section: string, index: number) {
        this.sectionVisibility[index][section] = !this.sectionVisibility[index][section];
    }

    isSectionVisible(section: string, index: number): boolean {
        return this.sectionVisibility[index][section];
    }

    toggleCollapse(index: number) {
        this.collapsedStates[index] = !this.collapsedStates[index];
    }

    formatTime(timeString: string): string {
        const timeParts = timeString.split(':');
        const date = new Date();
        date.setHours(Number(timeParts[0]));
        date.setMinutes(Number(timeParts[1]));

        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    }

    formatDuration(timeString: string, space: boolean): string {
        const timeParts = timeString.split(':');
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);

        let formattedDuration = '';

        if (hours > 0) {
            if (space) {
                formattedDuration += `${hours}h `;
            } else {
                formattedDuration += `${hours}h`;
            }
        }

        formattedDuration += `${minutes}m`;

        return formattedDuration;
    }

    formatBaggages(baggage: any): string {
        if (baggage?.number && baggage?.weight) {
            return `${baggage?.number}x${baggage?.weight} ${baggage?.unit}`;
        } else if (baggage?.number && baggage?.weight == null) {
            return `${baggage?.number} ${this.translateService.instant('COMMON.PCS')}`;
        } else if (baggage?.number == null && baggage?.weight) {
            return `${baggage?.weight} ${baggage?.unit}`;
        } else if (baggage?.description == 'included') {
            return this.translateService.instant('FLIGHT.VIEW_DETAILS_SIDEBAR.INCLUDED');
        } else
            return '';
    }

    toggleFareRule(index: number) {
        this.currentFareRule = index;
        this.fareRule = this.flightDetail?.flight_info?.itineraries[index]?.fare_rule;
    }

    onChangeYesOrNo(value: boolean) {
        if (value) {
            this.cancelForm.addControl('refundAmount', this.fb.control(null, [Validators.required]));
            this.cancelForm.addControl('deductAmount', this.fb.control(null, [Validators.required]));
        } else {
            this.cancelForm.removeControl('refundAmount');
            this.cancelForm.removeControl('deductAmount');
        }
        this.cancelForm.updateValueAndValidity();
    }

    onClickCancel() {
        this.isShowPopupDiscard = true;
    }

    onClickConfirm() {
        if (this.cancelForm.valid) {
            this.isShowPopupConfirm = true;
        } else {
            this.cancelForm.markAllAsTouched();
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            }, 0);
        }
    }

    private scrollToFirstInvalidControl() {
        let form = document.getElementById('cancelForm');
        let firstInvalidControl = form!.getElementsByClassName('is-invalid')[0];
        if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            (firstInvalidControl as HTMLElement).focus();
        }
    }

    onKeepEditing() {
        this.isShowPopupDiscard = false;
        this.isShowPopupConfirm = false;
    }

    onDiscard() {
        // event.stopPropagation();
        this.isShowPopupDiscard = false;
        this.backToStatusNote.emit();
    }

    onForceConfirm() {
        this.confirmCancel.emit();
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/user-management/default-image.jpg';
    }
}
