import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { CancelBookingComponent } from "./cancel-booking/cancel-booking.component";
import { RescheduleBookingComponent } from './reschedule-booking/reschedule-booking.component';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss'],
})
export class PriceDetailsComponent implements OnInit {
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  isShowCancelPopup: boolean = false;
  isShowReschedulePopup: boolean = false;
  serviceData = [
    {
      numSeat: 0,
      totalSeat: 0,
      numMeal: 0,
      totalMeal: 0,
      numExtraBaggage: 0,
      totalExtraBaggage: 0,
    },
    {
      numSeat: 0,
      totalSeat: 0,
      numMeal: 0,
      totalMeal: 0,
      numExtraBaggage: 0,
      totalExtraBaggage: 0,
    }
  ];
  collapsedStates: boolean[] = [];

  @Input() flightDetail: any;

  ngOnInit(): void {
    this.flightDetail.passenger_information.forEach((passenger: any) => {
      // SEAT
      if (passenger?.departure_seat?.length) {
        this.serviceData[0].numSeat += passenger.departure_seat.length;
        passenger.departure_seat.forEach((seat: any) => {
          this.serviceData[0].totalSeat += seat.price;
        });
        this.flightDetail?.price_details?.price_details
      }
      if (passenger?.return_seat?.length) {
        this.serviceData[1].numSeat += passenger.return_seat.length;
        passenger.return_seat.forEach((seat: any) => {
          this.serviceData[1].totalSeat += seat.price;
        });
      }
      // EXTRA_BAGGAGE
      if (passenger?.departure_extra_baggage?.length) {
        this.serviceData[0].numExtraBaggage += passenger.departure_extra_baggage.length;
        passenger.departure_extra_baggage.forEach((baggage: any) => {
          this.serviceData[0].totalExtraBaggage += baggage.price;
        });
      }
      if (passenger?.return_extra_baggage?.length) {
        this.serviceData[1].numExtraBaggage += passenger.return_extra_baggage.length;
        passenger.return_extra_baggage.forEach((baggage: any) => {
          this.serviceData[1].totalExtraBaggage += baggage.price;
        });
      }
      // MEAL
      if (passenger?.departure_meals?.length) {
        passenger.departure_meals.forEach((segment: any) => {
          if (segment.meals?.length) {
            this.serviceData[0].numMeal += segment.meals.length;
            segment.meals.forEach((meal: any) => {
              this.serviceData[0].totalMeal += meal.price;
            });
          }
        });
      }
      if (passenger?.return_meals?.length) {
        passenger.return_meals.forEach((segment: any) => {
          if (segment.meals?.length) {
            this.serviceData[1].numMeal += segment.meals.length;
            segment.meals.forEach((meal: any) => {
              this.serviceData[1].totalMeal += meal.price;
            });
          }
        });
      }
    });
    
    if (this.flightDetail?.price_details?.price_details?.length) {
      this.collapsedStates = new Array(this.flightDetail.price_details.price_details.length).fill(true); // Initialize collapsed states
    }
  }

  showCancelPopup(){
    this.isShowCancelPopup = true;
  }

  closeCancelPopup(){
    this.isShowCancelPopup = false;
  }

  showReschedulePopup() {
    this.isShowReschedulePopup = true;
  }

  closeReschedulePopup() {
    this.isShowReschedulePopup = false;
  }

  toggleCollapse(index: number) {
    this.collapsedStates[index] = !this.collapsedStates[index];
  }
}
