import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightBookingIssueControllerService } from '@booking/angular-booking-admin-service';
import { TIME_OUT_REQUEST } from '@shared/constant';
import moment from 'moment';
import { catchError, finalize, of, Subject, takeUntil, timeout } from 'rxjs';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';

@Component({
    selector: 'preview-e-ticket',
    templateUrl: './preview-e-ticket.component.html',
    styleUrls: ['./preview-e-ticket.component.scss'],
})
export class PreviewETicketComponent {
    reservationCode: string;
    segmentType: string;
    isLoading: boolean = true;
    previewData: any;
    segments: any[] = [];
    dateIssue: Date;
    timeIssue: string;
    currentYear: number;
    passengers: any[] = [];
    onDestroy$: Subject<void> = new Subject();

    constructor(
        private toastMessageService: ToastMessageService,
        private flightBookingIssueControllerService: FlightBookingIssueControllerService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.reservationCode = this.activatedRoute.snapshot.paramMap.get('reservationCode') ?? '';
        this.segmentType = this.activatedRoute.snapshot.paramMap.get('type') ?? '';

        if (this.segmentType !== 'depart' && this.segmentType !== 'arrival') {
            this.router.navigate(['/home']);
        }

        this.currentYear = new Date().getFullYear();
        this.getPreviewETicketData();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getPreviewETicketData() {
        this.flightBookingIssueControllerService.getInfo(this.reservationCode)
            .pipe(
                timeout(TIME_OUT_REQUEST),
                takeUntil(this.onDestroy$),
                catchError((error) => {
                    if (error.name === 'TimeoutError') {
                        this.toastMessageService.showError('TIME_OUT_REQUEST');
                    } else if (error.status === 0) {
                        this.toastMessageService.showError('ERR_INTERNET_DISCONNECTED');
                    } else {
                        this.toastMessageService.showError(error.error.error);
                    }
                    return of(null);
                }),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((res: any) => {
                if (res?.success) {
                    this.previewData = this.segmentType === 'depart' ? res.data.departure_flight : res.data.return_flight;
                    const requestedAt = this.previewData?.requested_at;
                    if (requestedAt) {
                        this.dateIssue = this.convertToUserTimeZone(requestedAt);
                        this.timeIssue = moment(requestedAt).format('HH:mm');
                    }
                    this.segments = this.previewData?.segments || [];
                }
            });
    }

    convertToUserTimeZone(utcTime: string): any {
        return moment.utc(utcTime).toDate();
    }

    getUTCOffset() {
        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        const offsetHours = Math.abs(timezoneOffsetInMinutes / 60);
        const offsetSign = timezoneOffsetInMinutes > 0 ? '-' : '+';
        return `(UTC${offsetSign}${offsetHours})`;
    }

    getTime(dateTime: any) {
        if (!dateTime) {
            return '';
        }
        return moment(dateTime).format('HH:mm');
    }

    getDate(dateTime: any) {
        if (!dateTime) {
            return '';
        }
        return moment(dateTime).format('YYYY-MM-DD');
    }

    formatDuration(timeString: string, space: boolean): string {
        if (!timeString) {
            return '';
        }
        
        const timeParts = timeString.split(':');
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);

        let formattedDuration = '';

        if (hours > 0) {
            if (space) {
                formattedDuration += `${hours}h `;
            } else {
                formattedDuration += `${hours}h`;
            }
        }

        formattedDuration += `${minutes}m`;

        return formattedDuration;
    }
}