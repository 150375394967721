<div class="!h-7 !w-7 flex justify-center items-center baggage-info" (mouseenter)="showBaggageTooltipInfo($event)"
    (mouseleave)="hideBaggageTooltipInfo()">
    <i class="sctr-icon-info-circle text-palette-blue-600 text-base !leading-5"></i>
    <ng-container *ngIf="isDirtyHover">
        <div class="popup-container absolute md:-right-[75%] sm:-right-[200px] scroll-auto border border-palette-gray-200 rounded-lg 
    !p-4 w-[400px] max-h-[400px] bg-white z-[14] overflow-y-auto transition-all" [ngStyle]="tooltipStyle"
            [class.show]="isShowBaggagePopup" (mouseenter)="keepBaggageTooltipInfo()"
            (mouseleave)="hideBaggageTooltipInfo()">
            <!-- HEADER -->
            <div class="flex flex-row !gap-2 !p-3 border border-palette-blue-600 rounded-xl bg-palette-blue-50">
                <i class="sctr-icon-info-circle text-palette-blue-600 text-xl"></i>
                <p class="text-palette-gray-700 font-normal text-sm">
                    <span>{{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.AIRLINE_BAGGAGE_RULE_VARY'
                        | translate}}</span>
                    <span class="font-semibold">{{SOCTRIP_HOTLINE}}</span>
                </p>
            </div>
            <!-- BODY -->
            <div *ngIf="flightInfo.source.toUpperCase() == SOURCE.VIETJET"
                class="text-palette-gray-900 text-sm space-y-3 !mt-3">
                <div class="space-y-1">
                    <p class="font-medium">
                        {{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.CABIN_BAGGAGE'
                        | translate}}:</p>
                    <p class="font-normal"><span class="text-palette-gray-400">•</span>
                        {{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.CABIN_BAGGAGE_DESR'
                        | translate}}</p>
                </div>
                <div class="space-y-1">
                    <p class="font-medium">
                        {{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.NORMAL_CHECKED_BAGGAGE'
                        | translate}}:</p>
                    <p class="font-normal"><span class="text-palette-gray-400 ">•</span>
                        {{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.NORMAL_CHECKED_BAGGAGE_WEIGHT_DESC'
                        | translate}}</p>
                    <p class="font-normal"><span class="text-palette-gray-400 ">•</span>
                        {{'BOOKING_HELPDESK.BAGGAGE_DETAILS_POPUP.NORMAL_CHECKED_BAGGAGE_DIMENSIONS_DESC'
                        | translate}}</p>
                </div>
            </div>
        </div>
    </ng-container>
</div>