import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { AuthAPI } from '../auth/api.auth.model';

export class AuthApi {
    private apiUrl: string = environment.baseURL;
    AUTH = '/auth';

    constructor(
        public readonly http: HttpClient,
        public config: ApiClientConfig
    ) { }

    signIn(data: AuthAPI.SignIn): Observable<AuthAPI.SignIn> {
        return this.http.post<AuthAPI.SignIn>(`${this.apiUrl}${this.AUTH}/signin`, data).pipe(
            timeout(this.config.responseTimeout),
            catchError(error => {
                throw error;
            })
        );
    }

    signOut(data: AuthAPI.SignOut): Observable<AuthAPI.SignOut> {
        return this.http.post<AuthAPI.SignOut>(`${this.apiUrl}${this.AUTH}/signout`, data).pipe(
          timeout(this.config.responseTimeout),
          catchError(error => {
            throw error.error;
          })
        );
      }

    thirdPartySignIn(data: AuthAPI.ThirdPartySignIn): Observable<AuthAPI.ThirdPartySignIn> {
        return this.http.post<AuthAPI.ThirdPartySignIn>(`${this.apiUrl}${this.AUTH}/third-party/signin`, data).pipe(
            timeout(this.config.responseTimeout),
            map(response => {
                return response;
            }),
            catchError(error => {
                throw error.error;
            })
        );
    }

    getDetailInfo(): Observable<AuthAPI.Regional> {
        return this.http.get<AuthAPI.Regional>(`${this.apiUrl}/regional/me`).pipe(
            timeout(this.config.responseTimeout),
            map(response => {
                return response;
            }),
            catchError(error => {
                throw error.error;
            })
        );
    }

    getLoginHistory(data: AuthAPI.LoginHistory): Observable<any> {
        let params = new HttpParams()
            .set('pageNum', data.pageNum)
            .set('pageSize', data.pageSize)
            .set('deviceId', data.device_id || '');

        if (data.filters) {
            let filterParams = '';
            const { id, ...rest } = data.filters;
            filterParams += `id!=${id}`;
            for (const [key, value] of Object.entries(rest)) {
                if (value) {
                    filterParams += `,${key}==${value}`;
                }
            }
            params = params.set('filters', filterParams);
        }
        return this.http.get<any>(`${this.apiUrl}${this.AUTH}/me/login-history`, { params }).pipe(
            timeout(this.config.responseTimeout),
            map(response => {
                return response;
            }),
            catchError(error => {
                throw error.error;
            })
        );
    }

    deleteLoginHistory(id: string): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}${this.AUTH}/me/login-history/${id}`).pipe(
            timeout(this.config.responseTimeout),
            catchError(error => {
                throw error.error;
            })
        );
    }

    signOutAllDevices(): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}${this.AUTH}/sign-out-all-devices`).pipe(
            timeout(this.config.responseTimeout),
            catchError(error => {
                throw error.error;
            })
        );
    }
}
