<div class="w-full h-full font-inter">
    <div class="w-[100%] rounded-xl border border-palette-gray-200 bg-white">
        <div class="p-3">
            <div class="flex justify-between items-center cursor-pointer" 
                (click)="handleClickExpandCollapse()"
                (mouseover)="isMouseOverExpand=true"
                (mouseleave)="isMouseOverExpand=false">
                <div class="flex items-center">
                    <span [ngClass]="{'!font-extrabold':isMouseOverExpand}" class="ml-2 text-lg font-semibold text-palette-gray-900">{{"FLIGHT.PASSENGERS.PASSENGERS" | translate}}</span>
                    <div @slideInOut *ngIf="!isExpand"  class="flex items-center ml-2">
                        <span *ngIf="totalAdults>0"> ({{totalAdults}} {{'FLIGHT.PASSENGERS.ADULT'| translate: {syntax: totalAdults>=2? "s": ""} | lowercase}}{{(totalChildren==0&&totalInfant==0)?")":""}}</span>
                        <span *ngIf="totalChildren>0">, {{totalChildren}} {{'FLIGHT.PASSENGERS.CHILD'| translate: {syntax: totalChildren>=2? "s":""} | lowercase}}{{(totalInfant==0)?")":""}}</span>
                        <span *ngIf="totalInfant>0">, {{totalInfant}} {{'FLIGHT.PASSENGERS.INFANT'| translate: {syntax: totalInfant>=2? "s": ""} | lowercase}})</span>
                    </div>
                    
                </div>
                <i [ngClass]="{'sctr-icon-chevron-down':isExpand, 'sctr-icon-chevron-right':!isExpand, 'font-extrabold':isMouseOverExpand}" class="text-xl leading-5 text-palette-gray-500"></i>
            </div>

            <div @slideDownUp *ngIf="isExpand" class="!gap-4 p-2 grid m-0 !mb-2" 
                [ngClass]="passengerList.length > 1 ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2' : 'grid-cols-1'">
                <div *ngFor="let item of passengerList; let i = index"
                    class="col-span-1 rounded-lg border border-palette-gray-200 bg-palette-gray-50 p-3 max-[767px]:mt-3"
                    [ngClass]="{'border-palette-red-200 bg-palette-red-50': item.passenger_type.toUpperCase() === 'INFANT'}">
                    <div class="!mb-3 text-sm font-semibold text-palette-gray-900">
                        {{
                        "FLIGHT.PASSENGERS." + item.passenger_type
                        | uppercase
                        | translate : { syntax: "" }
                        | titlecase
                        }}
                    </div>
                    <div class="flex flex-col !gap-3">
                        <!-- Full Name -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700 whitespace-nowrap">
                                {{ "FLIGHT.PASSENGERS.FULL_NAME" | translate }}
                            </div>
                            <div class="flex-1 relative flex justify-end">
                                <div class="line-clamp-1 text-sm font-medium text-palette-gray-900"
                                    [isTruncated]="true"
                                    [tooltip]="('COMMON.' + item?.title?.toUpperCase() | translate | uppercase) + ' ' + item?.last_name?.toUpperCase() + ' ' + item?.middle_and_first_name?.toUpperCase()">
                                    {{ 'COMMON.' + item.title?.toUpperCase() | translate | uppercase }} {{ item.last_name | uppercase }} {{ item.middle_and_first_name | uppercase }}
                                </div>
                            </div>
                        </div>
                        <!-- Nationality -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700">
                                {{ "FLIGHT.PASSENGERS.NATIONALITY" | translate }}
                            </div>
                            <div class="relative flex justify-end">
                                <div class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                    [isTruncated]="true" [tooltip]="item.nationality">
                                    {{ item.nationality }}
                                </div>
                            </div>
                        </div>
                        <!-- Date of birth -->
                        <div class="flex justify-between items-center !gap-4">
                            <div class="text-sm font-normal text-palette-gray-700">
                                {{ "FLIGHT.PASSENGERS.DATE_OF_BIRTH" | translate }}
                            </div>
                            <div class="truncate text-end text-sm font-medium text-palette-gray-900">
                                {{ item.date_of_birth | dateFormat }}
                            </div>
                        </div>
                        <!-- Passport -->
                        <ng-container *ngIf="item.passport_identity">
                            <div class="flex justify-between items-center !gap-4">
                                <div class="text-sm font-normal text-palette-gray-700">
                                    {{ "FLIGHT.PAYMENT.PASSPORT" | translate }}
                                </div>
                                <div class="relative flex justify-end">
                                    <div class="line-clamp-1 text-end text-sm font-medium text-palette-gray-900 max-w-full"
                                        [isTruncated]="true" [tooltip]="item.country + ' • ' + item.passport_identity">
                                        {{ item.country }} • {{ item.passport_identity }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between items-center !gap-4">
                                <div class="text-sm font-normal text-palette-gray-700">
                                    {{ "FLIGHT.PAYMENT.PASSPORT_EXPIRY" | translate }}
                                </div>
                                <div class="truncate text-end text-sm font-medium text-palette-gray-900">
                                    {{ item.passport_expiry_date | dateFormat}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>